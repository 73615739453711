import { useState } from 'react';
import { Canvas } from "@react-three/fiber"
import { Float, Lightformer, Html, Environment, OrbitControls, GradientTexture, MeshDistortMaterial, Sparkles, OrthographicCamera } from "@react-three/drei"
import { Route, Link, useLocation } from "wouter"
import { EffectComposer, Noise } from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";


import playIcon from './play.svg';
import sound from './sub-dreams.mp3';

import nav1 from './nav1.svg';
import nav2 from './nav2.svg';
import nav3 from './nav3.svg';
import nav4 from './nav4.svg';
import nav5 from './nav5.svg';
import nav6 from './nav6.svg';
import nav7 from './nav7.svg';


export const App = () => {

  const [isPlaying, setIsPlaying] = useState(false);
  const audio = new Audio(sound);

  const playAudio = () => {
    setIsPlaying(true);
    audio.play();
    audio.loop = true;
  };


  const [location] = useLocation(); // Obtient l'URL actuelle
  // Fonction pour déterminer si une page est active
  const isActive = (path) => location === path;


  return (
    <>
      <Canvas eventSource={document.getElementById("root")} eventPrefix="client" shadows camera={{ position: [0, 0, 20], fov: 50 }} className="fadeCanvas">

        <ambientLight intensity={Math.PI / 6} />
        <directionalLight position={[10, 13, 65]} />


        <Float floatIntensity={3}>
          <Route path="/">
            <HomePicture scale={3} />
          </Route>
          <Route path="/dream1">
            <Knot scale={1} />
          </Route>
          <Route path="/dream2">
            <Torus scale={0.9} />
          </Route>
          <Route path="/dream3">
            <Bomb scale={1} />
          </Route>
          <Route path="/dream4">
            <Dream4 scale={0.6} />
          </Route>
          <Route path="/dream5">
            <Dream5 scale={1} />
          </Route>
          <Route path="/infos">
            <Dream6 scale={0.5} />
          </Route>

        </Float>

        <Environment>
          <Lightformer intensity={1} position={[10, 5, 0]} scale={[20, 20, 1]} onUpdate={(self) => self.lookAt(0, 0, 0)} />
        </Environment>

        <OrthographicCamera makeDefault far={100} near={0.1} position={[-10, 2, -10]} zoom={65} />

        <OrbitControls autoRotate autoRotateSpeed={0.3} enableZoom={true} />

        <Sparkles count={400} scale={[120, 120, 120]} size={5} speed={0.1} color={'#b3b8f5'} />

        <EffectComposer>
          <Noise premultiply blendFunction={BlendFunction.REFLECT} />
        </EffectComposer>
        {/* MULTIPLY
        REFLECT */}

      </Canvas>


      {/* <div className="nav">
        <Link to="/"><img src={nav1} className="ImgNav"></img></Link>
        <Link to="/dream1"><img src={nav2} className="ImgNav delay1"></img></Link>
        <Link to="/dream2"><img src={nav3} className="ImgNav delay2"></img></Link>
        <Link to="/dream3"><img src={nav4} className="ImgNav delay3"></img></Link>
        <Link to="/dream4"><img src={nav5} className="ImgNav delay4"></img></Link>
        <Link to="/dream5"><img src={nav6} className="ImgNav delay5"></img></Link>
        <Link to="/infos"><img src={nav7} className="ImgNav delay6"></img></Link>
      </div> */}

      <div className="nav">
        <Link to="/"><img src={nav1} className={`ImgNav ${isActive('/') ? 'active' : ''}`}></img></Link>
        <Link to="/dream1"><img src={nav2} className={`ImgNav delay1 ${isActive('/dream1') ? 'active' : ''}`}></img></Link>
        <Link to="/dream2"><img src={nav3} className={`ImgNav delay2 ${isActive('/dream2') ? 'active' : ''}`}></img></Link>
        <Link to="/dream3"><img src={nav4} className={`ImgNav delay3 ${isActive('/dream3') ? 'active' : ''}`}></img></Link>
        <Link to="/dream4"><img src={nav5} className={`ImgNav delay4 ${isActive('/dream4') ? 'active' : ''}`}></img></Link>
        <Link to="/dream5"><img src={nav6} className={`ImgNav delay5 ${isActive('/dream5') ? 'active' : ''}`}></img></Link>
        <Link to="/infos"><img src={nav7} className={`ImgNav delay6 ${isActive('/infos') ? 'active' : ''}`}></img></Link>
      </div>

      {!isPlaying && (
        <div style={{ position: 'absolute', top: '50%', left: '50%', width: '120px', height: '120px', transform: 'translate(-50%, -50%)', zIndex: 1000 }}>
          <img className="btnplay" src={playIcon} alt="Play" onClick={playAudio} style={{ cursor: 'pointer' }} />
        </div>
      )}

      <footer className='info'>
        <p className='txtinfo'>Press play then rotate and zoom the differents shapes. Listen to the sound and explore the combinations that emerge.</p>
      </footer>

    </>
  );
};


//////////////
// forme 1
//////////////
const HomePicture = (props) => (
  < mesh castShadow>
    <sphereGeometry args={[5, 64, 64]} />
    <MeshDistortMaterial>
      <GradientTexture stops={[0.4, 0.1, 0.8]} colors={['#ff4ac3', '#ff4e36', '#f2b1e7']} size={100} />
    </MeshDistortMaterial>
    <Html position={[0, 3, 0]} className="text">
      <div>
        Subterranean Dreams,
      </div>
    </Html>
  </mesh >
)



//////////////
// forme 2
//////////////
const Knot = (props) => (
  <mesh castShadow>
    <torusKnotGeometry args={[10, 20, 90, 10]} scale={0.1} />
    <MeshDistortMaterial>
      <GradientTexture stops={[0.4, 0.2, 0.8]} colors={['#ddeb02', '#cc1b97', '#1c1b1b']} size={100} />
    </MeshDistortMaterial>
    <Html position={[0, -5, 0]} className="text">
      <div>
        Drone notes in dark, deep seams,
      </div>
    </Html>
  </mesh>
)



//////////////
// forme 3
//////////////
const Torus = (props) => (
  <mesh receiveShadow castShadow {...props}>
    <torusGeometry args={[4, 19, 28, 64]} />
    <MeshDistortMaterial>
      <GradientTexture stops={[0.4, 0.2, 0.8]} colors={['#ff4e36', '#f2b1e7', '#1c1b1b']} size={100} />
    </MeshDistortMaterial>
    <Html position={[0, -5, 0]} className="text">
      <div>
        Music flows, unseen.
      </div>
    </Html>
  </mesh>
)



//////////////
// forme 4
//////////////
const Bomb = (props) => (
  <mesh receiveShadow castShadow {...props}>
    <coneGeometry args={[30, 10, 26, 32]} />
    <MeshDistortMaterial>
      <GradientTexture stops={[0.4, 0.2, 0.8]} colors={['#DC84F3', '#4CB9E7', '#04364A']} size={100} />
    </MeshDistortMaterial>
    <Html position={[0, -5, 0]} className="text">
      <div>
        In the caverns of the mind,
      </div>
    </Html>
  </mesh>
)


//////////////
// forme 5
//////////////
const Dream4 = (props) => (
  <mesh receiveShadow castShadow {...props}>
    <dodecahedronGeometry args={[30, 10, 20, 30]} />
    <MeshDistortMaterial>
      <GradientTexture stops={[0.4, 0.2, 0.8]} colors={['#F31559', '#8DDFCB', '#04364A']} size={100} />
    </MeshDistortMaterial>
    <Html position={[0, -5, 0]} className="text">
      <div>
        Drone melodies unwind,
      </div>
    </Html>
  </mesh>
)



//////////////
// forme 6
//////////////
const Dream5 = (props) => (
  <mesh receiveShadow castShadow {...props}>
    <torusKnotGeometry args={[10, 20, 10, 10]} scale={0.6} />
    <MeshDistortMaterial>
      <GradientTexture stops={[0.4, 0.2, 0.8]} colors={['#FF7676', '#711DB0', '#FF9800']} size={100} />
    </MeshDistortMaterial>
    <Html position={[0, -5, 0]} className="text">
      <div>
        Echoes intertwined.
      </div>
    </Html>
  </mesh>
)

//////////////
// INFOS
//////////////
const Dream6 = (props) => (
  <mesh receiveShadow castShadow {...props}>
    <boxGeometry args={[10, 10, 12, 10]} />
    <MeshDistortMaterial>
      <GradientTexture stops={[0.2, 0.7, 0.8]} colors={['#008170', '#5C5470', '#BED754']} size={100} />
    </MeshDistortMaterial>
    <Html position={[5, 2, 0]} className="text">
      <div>
        website and sound by nicolas tilly
      </div>
    </Html>
  </mesh>
)



function Status(props) {
  const [loc] = useLocation()
  const text = loc === "/" ? "/HomePicture" : loc
}


export default App;